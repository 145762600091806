import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _, { update } from "lodash";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Pdf from "react-to-pdf";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import Spreadsheet from "react-spreadsheet";
import { ReactGrid, Highlight } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

import { styled } from "@mui/material/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import IntegrationInstructions from "@mui/icons-material/IntegrationInstructions";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import TableRows from "@mui/icons-material/TableRows";
import PrintIcon from "@mui/icons-material/Print";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MergeIcon from "@mui/icons-material/Merge";
import GroupsIcon from "@mui/icons-material/Groups";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import EditIcon from "@mui/icons-material/Edit";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AlbumIcon from "@mui/icons-material/Album";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DangerousIcon from "@mui/icons-material/Dangerous";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import AssessmentIcon from "@mui/icons-material/Assessment";

import SimpleInsetGraphBlock from "../Components/SubPlots/InsetPlots/SimpleInsetGraphBlock";
import CustomNodeLineandScatterPlot from "../Components/lineCharts/CustomNodeLineandScatterPlot";
import CustomNodeDataLabelsHover from "../Components/scatterPlots/CustomNodeDataLabelsHover";
import CustomNodeScatterPlotWithColorDimension from "../Components/scatterPlots/CustomNodeScatterPlotWithColorDimension";
import CustomNodeBasicLinePlot from "../Components/lineCharts/CustomNodeBasicLinePlot";
import CustomNodeLineandScatterPlotWithNames from "../Components/lineCharts/CustomNodeLineandScatterPlotWithNames";
import CustomLineandScatterStyling from "../Components/lineCharts/CustomLineandScatterStyling";
import CustomNodeStyledLinePlot from "../Components/lineCharts/CustomNodeStyledLinePlot";
import CustomNodeColoredandStyledScatterPlot from "../Components/lineCharts/CustomNodeColoredandStyledScatterPlot";
import CustomNodeLineShapeOptionsforInterpolation from "../Components/lineCharts/CustomNodeLineShapeOptionsforInterpolation";
import CustomNodeLineDash from "../Components/lineCharts/CustomNodeLineDash";
import CustomNodeBasicBarChart from "../Components/barCharts/CustomNodeBasicBarChart";
import CustomNodeGroupedBarChart from "../Components/barCharts/CustomNodeGroupedBarChart";
import CustomNodeBarChartwithHoverText from "../Components/barCharts/CustomNodeBarChartwithHoverText";
import CustomNodeBarChartwithDirectLabels from "../Components/barCharts/CustomNodeBarChartwithDirectLabels";
import CustomNodeGroupedBarChartwithDirectLabels from "../Components/barCharts/CustomNodeGroupedBarChartwithDirectLabels";
import CustomNodeCustomizingIndividualBarColors from "../Components/barCharts/CustomNodeCustomizingIndividualBarColors";
import CustomNodeCustomizingIndividualBarWidths from "../Components/barCharts/CustomNodeCustomizingIndividualBarWidths";
import CustomNodeCustomizingIndividualBarBase from "../Components/barCharts/CustomNodeCustomizingIndividualBarBase";
import CustomNodeColoredandStyledBarChart from "../Components/barCharts/CustomNodeColoredandStyledBarChart";
import CustomNodeBarChartwithRelativeBarmode from "../Components/barCharts/CustomNodeBarChartwithRelativeBarmode";
import CustomNodeBasicPieChart from "../Components/pieCharts/CustomNodeBasicPieChart";
import CustomNodePieChartSubplots from "../Components/pieCharts/CustomNodePieChartSubplots";
import CustomNodeDonutChart from "../Components/pieCharts/CustomNodeDonutChart";
import ControlTextOrientationInsidePieChartSectors from "../Components/pieCharts/ControlTextOrientationInsidePieChartSectors";
import CustomNodeBubbleSizeonBubbleChart from "../Components/bubbleCharts/CustomNodeBubbleSizeBubbleChart";
import CustomNodeMarkerSizeandColorBubbleChart from "../Components/bubbleCharts/CustomNodeMarkerSizeandColorBubbleChart";
import CustomNodeHoverTextonBubbleChart from "../Components/bubbleCharts/CustomNodeHoverTextonBubbleChart";
import CustomNodeMarkerSizeColorandSymbolasArray from "../Components/bubbleCharts/CustomNodeMarkerSizeColorandSymbolasArray";
import CustomNodeBasicOverlaidAreaChart from "../Components/filledAreaPlots/CustomNodeBasicOverlaidAreaChart";
import CustomNodeOverlaidAreaChartWithoutBoundaryLines from "../Components/filledAreaPlots/CustomNodeOverlaidAreaChartWithoutBoundaryLines";
import CustomNodeStackedAreaChart from "../Components/filledAreaPlots/CustomNodeStackedAreaChart";
import CustomNodeNormalizedStackedAreaChart from "../Components/filledAreaPlots/CustomNodeNormalizedStackedAreaChart";
import CustomNodeSelectHoverPoints from "../Components/filledAreaPlots/CustomNodeSelectHoverPoints";
import CustomNodeBarChartwithLinePlot from "../Components/HorizontalBarCharts/CustomNodeBarChartwithLinePlot";
import CustomNodeStyledPointCloud from "../Components/PointCloud/CustomNodeStyledPointCloud";
import BasicSunBurstChart from "../Components/SunburstCharts/BasicSunBurstChart";
import BranchValues from "../Components/SunburstCharts/BranchValues";
import SunburstWithRepetedLabels from "../Components/SunburstCharts/SunburstWithRepetedLabels";
import SunburstLargeNumberSlices from "../Components/SunburstCharts/SunburstLargeNumberSlices";
import SunburstTextOrientaion from "../Components/SunburstCharts/SunburstTextOrientaion";
import SankeyDiagrams from "../Components/SankeyDiagrams/SankeyDiagrams";
import CreateSankeyCanvas from "../Components/SankeyDiagrams/CreateSankeyCanvas";
import AddNodesBlock from "../Components/SankeyDiagrams/AddNodesBlock";
import AddLinksBlock from "../Components/SankeyDiagrams/AddLinksBlock";
import DefineNodePositionBlock from "../Components/SankeyDiagrams/DefineNodePositionBlock";
import StyleSankeyDiagramBlock from "../Components/SankeyDiagrams/StyleSankeyDiagramBlock";
import BasicSymmetricErrorBarsBlock from "../Components/ErrorBars/BasicSymmetricErrorBarsBlock";
import BarChartwithErrorBarsBlock from "../Components/ErrorBars/BarChartwithErrorBarsBlock";
import HorizontalErrorBarsBlock from "../Components/ErrorBars/HorizontalErrorBarsBlock";
import AsymmetricErrorBarsBlock from "../Components/ErrorBars/AsymmetricErrorBarsBlock";
import ColoredandStyledErrorBarsBlock from "../Components/ErrorBars/ColoredandStyledErrorBarsBlock";
import ErrorBarsasaPercentageoftheyValueBlock from "../Components/ErrorBars/ErrorBarsasaPercentageoftheyValueBlock";
import AsymmetricErrorBarswithaConstantOffsetBlock from "../Components/ContinuousErrorBars/AsymmetricErrorBarswithaConstantOffsetBlock";
import BoxPlotThatDisplayesUnderlyingdataBlock from "../Components/boxPlots/BoxPlotThatDisplayesUnderlyingdataBlock";
import BoxPlotStylingOutliersBlock from "../Components/boxPlots/BoxPlotStylingOutliersBlock";
import BoxPlotStylingMeanandStandardDeviationBlock from "../Components/boxPlots/BoxPlotStylingMeanandStandardDeviationBlock";
import GroupedHorizontalBoxPlotBlock from "../Components/boxPlots/GroupedHorizontalBoxPlotBlock";
import ColoredBoxPlotBlock from "../Components/boxPlots/ColoredBoxPlotBlock";
import RainBowBoxPlotBlock from "../Components/boxPlots/RainBowBoxPlotBlock";
import HorizontalHistogramBlock from "../Components/histograms/HorizontalHistogramBlock";
import OverlaidHistogramBlock from "../Components/histograms/OverlaidHistogramBlock";
import StackedHistogramBlock from "../Components/histograms/StackedHistogramBlock";
import NormalizedHistogramBlock from "../Components/histograms/NormalizedHistogramBlock";
import SpecifyBinningFunctionBlock from "../Components/histograms/SpecifyBinningFunctionBlock";
import TwoDHistogramContourPlotwithHistogramSubplotsBlock from "../Components/2dDensityPLots/2DHistogramContourPlotwithHistogramSubplotsBlock";
import SimpleContourPlotBlock from "../Components/ContourPlots/SimpleContourPlotBlock";
import BasicContourPlotBlock from "../Components/ContourPlots/BasicContourPlotBlock";
import SettingXandYCoordinatesinaContourPlotBlock from "../Components/ContourPlots/SettingXandYCoordinatesinaContourPlotBlock";
import ColorscaleforContourPlotBlock from "../Components/ContourPlots/ColorscaleforContourPlotBlock";
import CustomizingSpacingBetweenXandYTicksBlock from "../Components/ContourPlots/CustomizingSpacingBetweenXandYTicksBlock";
import ConnecttheGapsbetweenNullValuesintheZMatrixBlock from "../Components/ContourPlots/ConnecttheGapsbetweenNullValuesintheZMatrixBlock";
import SmoothingContourLinesBlock from "../Components/ContourPlots/SmoothingContourLinesBlock";
import SmoothContourColoringBlock from "../Components/ContourPlots/SmoothContourColoringBlock";
import ContourLineLabelsBlock from "../Components/ContourPlots/ContourLineLabelsBlock";
import ContourLinesBlock from "../Components/ContourPlots/ContourLinesBlock";
import CustomColorscaleforContourPlotBlock from "../Components/ContourPlots/CustomColorscaleforContourPlotBlock";
import ColorBarTitleBlock from "../Components/ContourPlots/ColorBarTitleBlock";
import ColorBarSizeBlock from "../Components/ContourPlots/ColorBarSizeBlock";
import StylingColorBarTicksforContourPlotsBlock from "../Components/ContourPlots/StylingColorBarTicksforContourPlotsBlock";
import BasicHeatmapBlock from "../Components/Heatmaps/BasicHeatmapBlock";
import HeatmapwithCategoricalAxisLabelsBlock from "../Components/Heatmaps/HeatmapwithCategoricalAxisLabelsBlock";
import BasicTernaryPlotwithMarkersBlock from "../Components/TerneryPlots/BasicTernaryPlotwithMarkersBlock";
import SoilTypesTernaryPlotBlock from "../Components/TerneryPlots/SoilTypesTernaryPlotBlock";
import AddingDimensionsBlock from "../Components/ParallelCoordinatesPlot/AddingDimensionsBlock";
import BasicParallelCoordinatesPlotBlock from "../Components/ParallelCoordinatesPlot/BasicParallelCoordinatesPlotBlock";
import AnnotatedParallelCoordinatesPlotBlock from "../Components/ParallelCoordinatesPlot/AnnotatedParallelCoordinatesPlotBlock";
import AdvancedParallelCoordinatesPlotBlock from "../Components/ParallelCoordinatesPlot/AdvancedParallelCoordinatesPlotBlock";
import LogarithmicAxesBlock from "../Components/LogPlots/LogarithmicAxesBlock";
import BasicWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/BasicWaterfallChartBlock";
import MultiCategoryWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/MultiCategoryWaterfallChartBlock";
import HorizontalWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/HorizontalWaterfallChartBlock";
import StyleWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/StyleWaterfallChartBlock";
import SimpleCandleStickChartBlock from "../Components/CandleStickCharts/SimpleCandleStickChartBlock";
import CandlestickChartwithoutRangesliderBlock from "../Components/CandleStickCharts/CandlestickChartwithoutRangesliderBlock";
import CustomiseCandlestickChartwithShapesandAnnotationsBlock from "../Components/CandleStickCharts/CustomiseCandlestickChartwithShapesandAnnotationsBlock";
import CustomizingCandlestickChartColorsBlock from "../Components/CandleStickCharts/CustomizingCandlestickChartColorsBlock";
import AddRangeselectorBlock from "../Components/CandleStickCharts/AddRangeselectorBlock";
import BasicFunnelPlotBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/BasicFunnelPlotBlock";
import SettingMarkerSizeandColorBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/SettingMarkerSizeandColorBlock";
import StackedFunnelBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/StackedFunnelBlock";
import FunnelareaPlotBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/FunnelareaPlotBlock";
import MultiFunnelareaBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/MultiFunnelareaBlock";
import DateStringsBlock from "../Components/FinancialCharts/TimeSeries/DateStringsBlock";
import BasicTimeSeriesBlock from "../Components/FinancialCharts/TimeSeries/BasicTimeSeriesBlock";
import ManuallySetRangeBlock from "../Components/FinancialCharts/TimeSeries/ManuallySetRangeBlock";
import TimeSerieswithRangesliderBlock from "../Components/FinancialCharts/TimeSeries/TimeSerieswithRangesliderBlock";
import ThreeDScatterPlotBlock from "../Components/3DCharts/3DScatterPlot/ThreeDScatterPlotBlock";
import BasicRibbonPlotBlock from "../Components/3DCharts/BasicRibbonPlot/BasicRibbonPlotBlock";
import Topographical3DSurfacePlotBlock from "../Components/3DCharts/3DSurfacePlots/Topographical3DSurfacePlotBlock";
import SurfacePlotWithContoursBlock from "../Components/3DCharts/3DSurfacePlots/SurfacePlotWithContoursBlock";
import Multiple3DSurfacePlotsBlock from "../Components/3DCharts/3DSurfacePlots/Multiple3DSurfacePlotsBlock";
import Simple3DMeshPlotBlock from "../Components/3DCharts/3DMeshPlots/Simple3DMeshPlotBlock";
import ThreeDMeshPlotwithAlphahullBlock from "../Components/3DCharts/3DMeshPlots/3DMeshPlotwithAlphahullBlock";
import ThreeDMeshTetrahedronBlock from "../Components/3DCharts/3DMeshPlots/3DMeshTetrahedronBlock";
import ThreeDMeshCubeBlock from "../Components/3DCharts/3DMeshPlots/3DMeshCubeBlock";
import ThreeDLinewithMarkersPlotBlock from "../Components/3DCharts/3DLinePlots/3DLinewithMarkersPlotBlock";
import ThreeDLineSpiralPlotBlock from "../Components/3DCharts/3DLinePlots/3DLineSpiralPlotBlock";
import ThreeDLinePlotBlock from "../Components/3DCharts/3DLinePlots/3DLinePlotBlock";
import ThreeDRandomWalkPlotBlock from "../Components/3DCharts/3DLinePlots/3DRandomWalkPlotBlock";
import SimpleSubplotBlock from "../Components/SubPlots/SubPlots/SimpleSubplotBlock";
import CustomSizedSubplotBlock from "../Components/SubPlots/SubPlots/CustomSizedSubplotBlock";
import MultipleSubplotsBlock from "../Components/SubPlots/SubPlots/MultipleSubplotsBlock";
import SubplotswithSharedAxesBlock from "../Components/SubPlots/SubPlots/SubplotswithSharedAxesBlock";
import StackedSubplotsBlock from "../Components/SubPlots/SubPlots/StackedSubplotsBlock";
import StackedSubplotswithaSharedXAxisBlock from "../Components/SubPlots/SubPlots/StackedSubplotswithaSharedXAxisBlock";
import MultipleCustomSizedSubplotsBlock from "../Components/SubPlots/SubPlots/MultipleCustomSizedSubplotsBlock";
import Multiple3DSubplotsBlock from "../Components/SubPlots/3DSubPLots/Multiple3DSubplotsBlock";
import MixedSubPlotsBlock from "../Components/SubPlots/MixedSubPlots/MixedSubPlotsBlock";
import TableandChartSubplotBlock from "../Components/SubPlots/TableSubplots/TableandChartSubplotBlock";
import ClickEventDataBlock from "../Components/CustomChartEvents/ClickEvents/ClickEventDataBlock";
import BindingToClickEventBlock from "../Components/CustomChartEvents/ClickEvents/BindingToClickEventBlock";
import HoverEventDataBlock from "../Components/CustomChartEvents/HoverEvents/HoverEventDataBlock";
import CapturingHoverEventsDataBlock from "../Components/CustomChartEvents/HoverEvents/CapturingHoverEventsDataBlock";
import CapturingHoverEventsPixelsBlock from "../Components/CustomChartEvents/HoverEvents/CapturingHoverEventsPixelsBlock";
import TriggeringHoverEventsBlock from "../Components/CustomChartEvents/HoverEvents/TriggeringHoverEventsBlock";
import CoupledHoverEventsBlock from "../Components/CustomChartEvents/HoverEvents/CoupledHoverEventsBlock";
import CombinedClickandHoverEventsBlock from "../Components/CustomChartEvents/HoverEvents/CombinedClickandHoverEventsBlock";
import DisablingZoomEventsforXAxisBlock from "../Components/CustomChartEvents/DisableZoomEvents/DisablingZoomEventsforXAxisBlock";
import DisablingZoomEventsforXandYAxisBlock from "../Components/CustomChartEvents/DisableZoomEvents/DisablingZoomEventsforXandYAxisBlock";
import FilterBlock from "../Components/Transforms/FilterBlock";
import GroupByBlock from "../Components/Transforms/GroupBy/GroupByBlock";
import AggregationsBlock from "../Components/Transforms/Aggregations/AggregationsBlock";
import AggregateFunctionsBlock from "../Components/Transforms/Aggregations/AggregateFunctionsBlock";
import HistogramBinningBlock from "../Components/Transforms/Aggregations/HistogramBinningBlock";
import MappingWithAggregatesBlock from "../Components/Transforms/Aggregations/MappingWithAggregatesBlock";
import FilterAndGroupbyBlock from "../Components/Transforms/MultipleTransforms/FilterAndGroupbyBlock";
import FilterAndAggregatesBlock from "../Components/Transforms/MultipleTransforms/FilterAndAggregatesBlock";
import AllTransformsBlock from "../Components/Transforms/MultipleTransforms/AllTransformsBlock";
import AddTwoDropdownMenustoaChartBlock from "../Components/CustomControls/DropDownEvents/AddTwoDropdownMenustoaChartBlock";
import BinddropdowneventstochartsBlock from "../Components/CustomControls/DropDownEvents/BinddropdowneventstochartsBlock";
import RestyleButtonSingleAttributeBlock from "../Components/CustomControls/ButtonEvents/RestyleButtonSingleAttributeBlock";
import RestyleButtonMultipleAttributesBlock from "../Components/CustomControls/ButtonEvents/RestyleButtonMultipleAttributesBlock";
import RelayoutButtonBlock from "../Components/CustomControls/ButtonEvents/RelayoutButtonBlock";
import UpdateButtonBlock from "../Components/CustomControls/ButtonEvents/UpdateButtonBlock";
import AnimateButtonBlock from "../Components/CustomControls/ButtonEvents/AnimateButtonBlock";
import StylethebuttonsBlock from "../Components/CustomControls/ButtonEvents/StylethebuttonsBlock";
import BasicSliderBlock from "../Components/CustomControls/SliderEvents/BasicSliderBlock";
import BindComponentstotheAppearanceofaPlotBlock from "../Components/CustomControls/SliderEvents/BindComponentstotheAppearanceofaPlotBlock";
import AddaPlayButtontoControlaSliderBlock from "../Components/CustomControls/SliderEvents/AddaPlayButtontoControlaSliderBlock";
import LassoSelectionBlock from "../Components/CustomControls/LassoSelection/LassoSelectionBlock";
import BasicRangeSlideronTimeSeriesBlock from "../Components/CustomControls/RangeSliderandSelector/BasicRangeSlideronTimeSeriesBlock";
import AnimatingtheDataBlock from "../Components/Animations/Animations/AnimatingtheDataBlock";
import AnimatingtheLayoutBlock from "../Components/Animations/Animations/AnimatingtheLayoutBlock";
import DefiningNamedFramesaddFramesBlock from "../Components/Animations/Animations/DefiningNamedFramesaddFramesBlock";
import AnimatingSequencesofFramesBlock from "../Components/Animations/Animations/AnimatingSequencesofFramesBlock";
import AnimatingManyFramesQuicklyBlock from "../Components/Animations/Animations/AnimatingManyFramesQuicklyBlock";
import ObjectConstancyBlock from "../Components/Animations/Animations/ObjectConstancyBlock";
import FrameGroupsandAnimationModesBlock from "../Components/Animations/Animations/FrameGroupsandAnimationModesBlock";
import AnimatingwithaSliderBlock from "../Components/Animations/Animations/AnimatingwithaSliderBlock";
import FilledAreaAnimationBlock from "../Components/Animations/FilledAreaAnimation/FilledAreaAnimationBlock";
import MultipleTraceFilledAreaBlock from "../Components/Animations/FilledAreaAnimation/MultipleTraceFilledAreaBlock";
import MapAnimationsBlock from "../Components/Animations/MapAnimation/MapAnimationsBlock";
import BasicTreemapsBlock from "../Components/TreeMaps/BasicTreemapsBlock";
import SetDifferentAttributesinTreeMapBlock from "../Components/TreeMaps/SetDifferentAttributesinTreeMapBlock";
import SetColorofTreemapSectorsBlock from "../Components/TreeMaps/SetColorofTreemapSectorsBlock";
import NestedLayersinTreemapBlock from "../Components/TreeMaps/NestedLayersinTreemapBlock";
import ContourandScatterPlotoftheMethodofSteepestDescentBlock from "../Components/MultipleChartTypes/ContourandScatterPlotoftheMethodofSteepestDescentBlock";
import LineChartandBarChartBlock from "../Components/MultipleChartTypes/LineChartandBarChartBlock";
import WebGLwithOneLakhpointsBlock from "../Components/WebGL/WebGLwithOneLakhpointsBlock";
import WebGLwithOneMillionpointsBlock from "../Components/WebGL/WebGLwithOneMillionpointsBlock";
import WebGLwithmanytracesBlock from "../Components/WebGL/WebGLwithmanytracesBlock";
import CustomNodeGraphandAxesTitles from "../Components/lineCharts/CustomNodeGraphandAxesTitles";
import CustomNodeDataLabelsOnThePlot from "../Components/scatterPlots/CustomNodeDataLabelsOnThePlot";
import CustomNodeConnectGapsBetweenData from "../Components/lineCharts/CustomNodeConnectGapsBetweenData";
import CustomNodeWaterfallBarChart from "../Components/barCharts/CustomNodeWaterfallBarChart";
import CustomNodeStackedBarChart from "../Components/barCharts/CustomNodeStackedBarChart";
import CustomNodeAutomaticallyAdjustMargin from "../Components/pieCharts/CustomNodeAutomaticallyAdjustMargin";
import CustomNodeCategoricalDotPlot from "../Components/DotPlot/CustomNodeCategoricalDotPlot";
import CustomNodeBasicHorizontalBarChart from "../Components/HorizontalBarCharts/CustomNodeBasicHorizontalBarChart";
import CustomNodeBasicPointCloud from "../Components/PointCloud/CustomNodeBasicPointCloud";
import BasicBoxPlotBlock from "../Components/boxPlots/BasicBoxPlotBlock";
import HorizontalBoxPlotBlock from "../Components/boxPlots/HorizontalBoxPlotBlock";
import GroupedBoxPlotBlock from "../Components/boxPlots/GroupedBoxPlotBlock";
import FullyStyledBoxPlotBlock from "../Components/boxPlots/FullyStyledBoxPlotBlock";
import ColoredandStyledHistogramBlock from "../Components/histograms/ColoredandStyledHistogramBlock";
import CumulativeHistogramBlock from "../Components/histograms/CumulativeHistogramBlock";
import TwoDHistogramContourPlotwithSliderControlBlock from "../Components/2dDensityPLots/2DHistogramContourPlotwithSliderControlBlock";
import FilledLinesBlock from "../Components/ContinuousErrorBars/FilledLinesBlock";
import CustomizingSizeandRangeofaContourPlotContoursBlock from "../Components/ContourPlots/CustomizingSizeandRangeofaContourPlotContoursBlock";
import AnnotatedHeatmapBlock from "../Components/Heatmaps/AnnotatedHeatmapBlock";
import HeatmapwithUnequalBlockSizesBlock from "../Components/Heatmaps/HeatmapwithUnequalBlockSizesBlock";
import CreateannotationonclickeventBlock from "../Components/CustomChartEvents/ClickEvents/CreateannotationonclickeventBlock";
import BasicHistogramBlock from "../Components/histograms/BasicHistogramBlock";
import { elementsCustom } from "../../src/graphData";
import BindingtoZoomEventsBlock from "../Components/CustomChartEvents/ZoomEvents/BindingtoZoomEventsBlock";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
var length1,
  flag = 0;
var preparedData1;

const MyComponent = ({ rowsData, columnData }) => {
  // console.log('I am in the My component response as well')
  console.log("jsjsj", rowsData, columnData);
  return (
    <div
      className="screen1"
      style={{
        width: window.innerWidth - 160,
        height: window.innerHeight / 1.8,
        overflow: "scroll",
        paddingTop: "20px",
        margin: "auto",
      }}
    >
      {/* <Spreadsheet data={data} /> */}
      {rowsData && (
        <ReactGrid
          rows={rowsData}
          columns={columnData}
          // enableRangeSelection={fillHandle ? true : false}
          // enableFillHandle={fillHandle ? true : false}
          // enableFullWidthHeader
          // enableRowSelection={rowSelection ? true : false}
          // enableColumnSelection={columnSelection ? true : false}
          // stickyTopRows={stickyHeader ? 1 : 0}
          // onColumnResized={columnResize ? handleColumnResize : null}
          // onCellsChanged={editMode ? handleChanges : null}
          // onContextMenu={simpleHandleContextMenu}
          // onFocusLocationChanged={handleFocus}
          // highlights={highlightOn ? highlight : []}
        />
      )}
    </div>
  );
};

const actions = [
  { icon: <FilterAltIcon />, name: "Filter" },
  { icon: <MergeIcon />, name: "Merge" },
  { icon: <GroupsIcon />, name: "Group" },
  { icon: <LineAxisIcon />, name: "Slice" },
  { icon: <ImportExportIcon />, name: "Sort" },
  { icon: <EditIcon />, name: "Rename Columns" },
  { icon: <MergeTypeIcon />, name: "Record Merge" },
  { icon: <ContentCopyIcon />, name: "Duplicate" },
  { icon: <ScreenSearchDesktopIcon />, name: "Fuzzy Search" },
  { icon: <AlbumIcon />, name: "Standardization" },
  { icon: <FindReplaceIcon />, name: "Replace Null" },
  { icon: <DangerousIcon />, name: "Incomplete" },
  { icon: <FormatTextdirectionLToRIcon />, name: "Formatted" },
  { icon: <FormatShapesIcon />, name: "Case Format" },
  { icon: <AssessmentIcon />, name: "Discrete Range" },
];
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));
const exportModalstyle = {
  position: "relative",
  top: "35%",
  left: "30%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  height: 180,
  width: 500,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  borderRadius: 1,
};

const options = {
  orientation: "portrait",
  unit: "in",
  format: [25.4, 60],
};
export default class ClassComp extends React.Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 100,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.csvLink = React.createRef();
    console.log("this.props.columns", this.props.columns);

    this.state = {
      items: [0].map(function (i, key, list) {
        return {
          i: i.toString(),
          x: i * 4,
          y: 0,
          w: 4,
          h: 3,
          add: i === list.length + 1,
        };
      }),
      newCounter: 0,
      finalData: this.props.data.data,
      category: "",
      category2: "",
      category3: "",
      category4: "",
      category5: "",
      category6: "",
      categoryChanged: false,
      categorySelected1: false,
      categorySelected2: false,
      categorySelected3: false,
      categorySelected4: false,
      categorySelected5: false,
      categorySelected6: false,
      dragMode: false,
      clickedExport: false,
      isExport: false,
      fileName: "",
      isExportCSV: false,
      isExportHTML: false,
      isExportPDF: false,
      rowsData: undefined,
      columnData: undefined,
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
  }

  componentDidMount() {
    //   console.log('I am in the Console  log of the Class COmponent Props')
    // console.log('propdata', this.props.data22, this.props.data23)
    // console.log('selected nodes', this.props.widgetsCount)
    //   console.log('didmount', this.props)

    this.setState({
      items: [0, 1].map(function (i, key, list) {
        return {
          i: i.toString(),
          x: i * 4,
          y: 0,
          w: 4,
          h: 3,
          add: i == list.length,
        };
      }),
    });
    flag = 1;

    this.setUpDataForGrid(this.props.data2);
  }

  printDiv = () => {
    var pageHTML = this.ref2.current.outerHTML;
    //  var printContents = document.getElementById('capture').innerHTML
    //var originalContents = document.body.innerHTML

    // document.body.innerHTML = pageHTML
    var openWindow = window.open("", "title", "attributes");
    openWindow.document.write(pageHTML);
    openWindow.document.close();
    openWindow.focus();
    openWindow.print();
    openWindow.close();

    //  window.print()
    //   document.body.innerHTML = originalContents
  };
  exportCSV = () => {
    //  <CSVDownload data={data && data} target='_blank' />
    //  console.log('CSV')
    this.csvLink.current.link.click();
  };

  _exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      //  document.body.appendChild(canvas) // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a3", false);
      pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
      pdf.save(this.state.fileName + ".pdf");
    });
    this.setState({ clickedExport: false });
  };
  exportToHtml = () => {
    // console.log('this.ref', this.ref)
    var pageHTML = this.ref3.current.outerHTML;
    //  var pageHTML = window.document.getElementById('capture').innerHTML
    console.log(pageHTML);
    let data = new Blob([pageHTML], { type: "data:attachment/text," });
    let csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", this.state.fileName + ".html");
    tempLink.click();
    this.setState({ clickedExport: false });
  };

  setUpDataForGrid = (data) => {
    let columns = data[0];
    // console.log('RRRRR', columns)
    console.log("DDDAA", data);
    let columnsBox = [];
    let cells = [];
    columns.map((e) => {
      columnsBox.push({
        columnId: e,
        width: 150,
        resizable: true,
        reorderable: true,
      });
      cells.push({
        type: "header",
        text: e,
      });
    });
    this.setState({
      columnData: columnsBox,
    });
    // setColumnData(columnsBox)

    let rowsBox = [];

    rowsBox.push({
      rowId: "header",
      cells: cells,
    });
    let c = 0;

    let data1 = [...data];
    data1.shift();
    data1.map((e) => {
      let cells1 = [];
      e.map((e2) => {
        cells1.push({
          id: c,
          type: "text",
          text: typeof e2 === "string" ? e2 : e2.toString(),
        });

        // console.log('In ExploreCols', cells1)
      });

      rowsBox.push({
        rowId: c,
        cells: cells1,
      });
      c++;
    });

    console.log("In ExploreCols", rowsBox);
    this.setState({
      rowsData: rowsBox,
    });
    // setrowsData(rowsBox)
  };

  componentDidUpdate(prevState, prevProps) {
    length1 =
      this.props.widgetsCount == 1
        ? [0]
        : this.props.widgetsCount == 2
        ? [0, 1]
        : this.props.widgetsCount == 3
        ? [0, 1, 2]
        : this.props.widgetsCount == 4
        ? [0, 1, 2, 3]
        : this.props.widgetsCount == 5
        ? [0, 1, 2, 3, 4]
        : this.props.widgetsCount == 6
        ? [0, 1, 2, 3, 4, 5]
        : [0, 1];
    //console.log('didupdate', length1)
    //  console.log('prevstate', prevState, flag)
    console.log("isExport", this.state.isExport);
    console.log("Box-", this.props.box);
    console.log(
      "SelectedCharts",
      this.props.selectedCharts,
      this.props.xaxisNumeric
    );
    console.log(
      "Check-",
      this.props.xaxisNumeric === true,
      this.props.yaxisNumeric == true
    );

    if (flag == 1) {
      this.setState({
        items: length1.map(function (i, key, list) {
          //    console.log('ERER', i)
          return {
            i: i.toString(),
            x:
              i == 0
                ? 0
                : i == 1
                ? 4
                : i == 2
                ? 8
                : i == 3
                ? 0
                : i == 4
                ? 4
                : i == 5
                ? 8
                : i * 4,
            y: 0,
            w: 4,
            h: 3,
            add: i == list.length,
          };
        }),
      });

      //  console.log('flagchanged', flag, this.props.data4)

      flag = 2;
    } else {
      //do something else
      flag = 1;
    }
  }
  createElement(el) {
    let data = {
      labels: ["January", "February", "March", "April", "May", "June"],
      datasets: [
        {
          data: [20, 45, 28, 80, 99, 43],
          color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`, // optional
          strokeWidth: 2, // optional
        },
      ],
      // legend: ["Rainy Days"] // optional
    };

    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: -2,
      right: 10,
      cursor: "pointer",
      color: "#b86b31",
    };
    const searchStyle = {
      position: "absolute",
      right: "2px",
      top: 4,
      right: 40,
      cursor: "pointer",
      color: "#b86b31",
    };
    const i = el.add ? "+" : el.i;

    if (this.state.finalData) {
      data.labels = Array.from(Object.keys(this.state.finalData[0]));
      let tempArr = [];
      data.labels.map((e) => {
        //   console.log(e)
        const countTypes = this.state.finalData.filter(
          (e1) => e1.Sprints === e
        );
        //     console.log(countTypes)
        tempArr.push(countTypes.length);
        //   console.log(tempArr)
      });
      data.datasets[0].data = tempArr;
    }

    return (
      <div
        style={{
          border: "0.5px solid #067AB4",
          borderTopLeftRadius: 22,
          borderTopRightRadius: 22,
          overflow: "hidden",
          height: 500,
          width: 550,
          cursor: "pointer",
        }}
        key={i}
        data-grid={el}
      >
        <div
          style={{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            height: 25,
            width: "100%",
            background: "#067AB4",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "hidden",
            cursor: "pointer",
          }}
        >
          {elementsCustom.map((element, index) => {
            return i == 0
              ? this.props.selectedCharts &&
                  this.props.selectedCharts[0] &&
                  this.props.selectedCharts[0].type == element.type && (
                    <div
                      style={{
                        fontSize: 12,
                        color: "white",
                        fontFamily: "Trebuchet MS",
                        fontWeight: "bold",
                      }}
                    >
                      {element.data.text}
                    </div>
                  )
              : i == 1
              ? this.props.selectedCharts &&
                this.props.selectedCharts[1] &&
                this.props.selectedCharts[1].type == element.type && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "white",
                      fontFamily: "Trebuchet MS",
                      fontWeight: "bold",
                    }}
                  >
                    {element.data.text}
                  </div>
                )
              : i == 2
              ? this.props.selectedCharts &&
                this.props.selectedCharts[2] &&
                this.props.selectedCharts[2].type == element.type && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "white",
                      fontFamily: "Trebuchet MS",
                      fontWeight: "bold",
                    }}
                  >
                    {element.data.text}
                  </div>
                )
              : i == 3
              ? this.props.selectedCharts &&
                this.props.selectedCharts[3] &&
                this.props.selectedCharts[3].type == element.type && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "white",
                      fontFamily: "Trebuchet MS",
                      fontWeight: "bold",
                    }}
                  >
                    {element.data.text}
                  </div>
                )
              : i == 4
              ? this.props.selectedCharts &&
                this.props.selectedCharts[4] &&
                this.props.selectedCharts[4].type == element.type && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "white",
                      fontFamily: "Trebuchet MS",
                      fontWeight: "bold",
                    }}
                  >
                    {element.data.text}
                  </div>
                )
              : i == 5
              ? this.props.selectedCharts &&
                this.props.selectedCharts[5] &&
                this.props.selectedCharts[5].type == element.type && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "white",
                      fontFamily: "Trebuchet MS",
                      fontWeight: "bold",
                    }}
                  >
                    {element.data.text}
                  </div>
                )
              : null;
          })}
        </div>
        <div
          style={{
            alignSelf: "center",
            marginLeft: 35,
            marginTop: 18,
          }}
        >
          {this.state.clickedExport === false &&
            elementsCustom.map((element, index) => {
              return i == 0 &&
                this.props.selectedCharts &&
                this.props.selectedCharts[0] &&
                this.props.selectedCharts[0].type == element.type ? (
                <div style={{ marginTop: -10 }}>
                  <select
                    onChange={(e) => {
                      this.setState({ category: e.target.value });
                      //    console.log('VV', e)
                      //  console.log('ll')
                    }}
                    style={{
                      border: "1px solid #FFF",
                      width: 150,
                      height: 25,
                      marginTop: -40,
                      marginRight: 10,
                      marginLeft: -5,
                      backgroundcolor: "#b86b31",
                      border: "0.5px solid",
                      borderRadius: 10,
                      fontSize: 12,
                      color: "#067AB4",
                    }}
                  >
                    <option
                      style={{ fontSize: 14, height: 25, marginBottom: 20 }}
                      value={0}
                    >
                      {"select Category"}
                    </option>
                    {element.data.categories &&
                      element.data.categories.map((ele) => (
                        <option
                          style={{ fontSize: 14, height: 25 }}
                          value={ele.type}
                        >
                          {ele.label}
                        </option>
                      ))}
                  </select>
                </div>
              ) : i == 1 &&
                this.props.selectedCharts &&
                this.props.selectedCharts[1] &&
                this.props.selectedCharts[1].type == element.type ? (
                <div style={{ marginTop: -10 }}>
                  <select
                    onChange={(e) => {
                      this.setState({ category2: e.target.value });
                    }}
                    style={{
                      border: "1px solid #FFF",
                      width: 150,
                      height: 25,
                      marginTop: -20,
                      marginRight: 10,
                      marginLeft: -5,
                      backgroundcolor: "#b86b31",
                      border: "0.5px solid",
                      borderRadius: 10,
                      fontSize: 12,
                      color: "#067AB4",
                    }}
                  >
                    <option style={{ fontSize: 14, height: 25 }} value={0}>
                      {"select Category"}
                    </option>
                    {element.data.categories &&
                      element.data.categories.map((ele) => (
                        <option
                          style={{ fontSize: 14, height: 25 }}
                          value={ele.type}
                        >
                          {ele.label}
                        </option>
                      ))}
                  </select>
                </div>
              ) : i == 2 &&
                this.props.selectedCharts &&
                this.props.selectedCharts[2] &&
                this.props.selectedCharts[2].type == element.type ? (
                <div style={{ marginTop: -10 }}>
                  <select
                    onChange={(e) => {
                      this.setState({ category3: e.target.value });
                    }}
                    style={{
                      border: "1px solid #FFF",
                      width: 150,
                      height: 25,
                      marginTop: -40,
                      marginRight: 10,
                      marginLeft: -5,
                      backgroundcolor: "#b86b31",
                      border: "0.5px solid",
                      borderRadius: 10,
                      fontSize: 12,
                      color: "#067AB4",
                    }}
                  >
                    <option style={{ fontSize: 14, height: 25 }} value={0}>
                      {"select Category"}
                    </option>
                    {element.data.categories &&
                      element.data.categories.map((ele) => (
                        <option
                          style={{ fontSize: 14, height: 25 }}
                          value={ele.type}
                        >
                          {ele.label}
                        </option>
                      ))}
                  </select>
                </div>
              ) : i == 3 &&
                this.props.selectedCharts &&
                this.props.selectedCharts[3] &&
                this.props.selectedCharts[3].type == element.type ? (
                <div style={{ marginTop: -10 }}>
                  <select
                    onChange={(e) => {
                      this.setState({ category4: e.target.value });
                    }}
                    style={{
                      border: "1px solid #FFF",
                      width: 150,
                      height: 25,
                      marginTop: -40,
                      marginRight: 10,
                      marginLeft: -5,
                      backgroundcolor: "#b86b31",
                      border: "0.5px solid",
                      borderRadius: 10,
                      fontSize: 12,
                      color: "#067AB4",
                    }}
                  >
                    <option style={{ fontSize: 14, height: 25 }} value={0}>
                      {"select Category"}
                    </option>
                    {element.data.categories &&
                      element.data.categories.map((ele) => (
                        <option
                          style={{ fontSize: 14, height: 25 }}
                          value={ele.type}
                        >
                          {ele.label}
                        </option>
                      ))}
                  </select>
                </div>
              ) : i == 4 &&
                this.props.selectedCharts &&
                this.props.selectedCharts[4] &&
                this.props.selectedCharts[4].type == element.type ? (
                <div style={{ marginTop: -10 }}>
                  <select
                    onChange={(e) => {
                      this.setState({ category5: e.target.value });
                    }}
                    style={{
                      border: "1px solid #FFF",
                      width: 150,
                      height: 25,
                      marginTop: -40,
                      marginRight: 10,
                      marginLeft: -5,
                      backgroundcolor: "#b86b31",
                      border: "0.5px solid",
                      borderRadius: 10,
                      fontSize: 12,
                      color: "#067AB4",
                    }}
                  >
                    <option style={{ fontSize: 14, height: 25 }} value={0}>
                      {"select Category"}
                    </option>
                    {element.data.categories &&
                      element.data.categories.map((ele) => (
                        <option
                          style={{ fontSize: 14, height: 25 }}
                          value={ele.type}
                        >
                          {ele.label}
                        </option>
                      ))}
                  </select>
                </div>
              ) : i == 5 &&
                this.props.selectedCharts &&
                this.props.selectedCharts[5] &&
                this.props.selectedCharts[5].type == element.type ? (
                <div style={{ marginTop: -10 }}>
                  <select
                    onChange={(e) => {
                      this.setState({ category6: e.target.value });
                    }}
                    style={{
                      border: "1px solid #FFF",
                      width: 150,
                      height: 25,
                      marginTop: -40,
                      marginRight: 10,
                      marginLeft: -5,
                      backgroundcolor: "#b86b31",
                      border: "0.5px solid",
                      borderRadius: 10,
                      fontSize: 12,
                      color: "#067AB4",
                    }}
                  >
                    <option style={{ fontSize: 14, height: 25 }} value={0}>
                      {"select Category"}
                    </option>
                    {element.data.categories &&
                      element.data.categories.map((ele) => (
                        <option
                          style={{ fontSize: 14, height: 25 }}
                          value={ele.type}
                        >
                          {ele.label}
                        </option>
                      ))}
                  </select>
                </div>
              ) : null;
            })}

          {i == 0 &&
          this.props.selectedCharts &&
          this.props.selectedCharts[0] &&
          this.props.selectedCharts[0].type == "special20" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 450,
              }}
            >
              {this.state.category == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special21" ? (
            <div style={{ display: "flex", overflow: "hidden", height: 208 }}>
              <div
                style={{
                  marginLeft: -30,
                  overflow: "hidden",
                  height: 400,
                }}
              >
                {this.state.category == "special20" ? (
                  <CustomNodeLineandScatterPlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category == "special21" ? (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category == "special22" ? (
                  <CustomNodeDataLabelsOnThePlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category == "special23" ? (
                  <CustomNodeScatterPlotWithColorDimension
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                )}
              </div>
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special22" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special23" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special24" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special25" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special26" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special27" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special28" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special29" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special30" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special31" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special32" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special33" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special34" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special35" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special36" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special37" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special38" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special39" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special40" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special41" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special42" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special43" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special44" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special45" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special46" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special47" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special48" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special49" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special50" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special51" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special52" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special53" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special54" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special55" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special56" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special57" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <CustomNodeCategoricalDotPlot
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special58" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special59" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special60" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special61" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special62" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special63" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special64" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special65" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special66" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special67" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special68" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special69" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special70" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special71" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special72" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special73" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special74" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special75" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special76" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special77" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special78" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special81" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special82" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special83" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special84" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special85" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special86" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special87" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special88" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special89" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special90" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special91" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special92" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special93" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special94" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special95" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special96" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special97" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special98" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special99" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special100" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special101" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special102" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special103" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special104" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special105" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special106" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special107" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special108" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special109" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special110" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special111" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special112" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special113" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special114" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special115" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special116" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special120" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special121" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special122" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special123" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special124" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special125" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special126" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special127" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special128" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special129" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special130" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special131" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special132" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special133" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special134" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special135" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special136" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special137" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special138" ? (
            <LogarithmicAxesBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
              IsDataNumeric={
                this.props.xaxisNumeric === true &&
                this.props.yaxisNumeric === true
                  ? true
                  : false
              }
            />
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special139" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special140" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special141" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special142" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special147" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special148" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special149" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special150" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special151" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special152" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special153" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special154" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special155" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special156" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special157" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special158" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special159" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special160" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special161" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special162" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special163" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special164" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special165" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special166" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special167" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special168" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special169" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special170" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special170" ? (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special171" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special70" ? (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special172" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special173" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special174" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special175" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special176" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special177" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special178" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special179" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special180" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special181" ? (
            <SimpleInsetGraphBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
            />
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special182" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <Multiple3DSubplotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true &&
                  this.props.zaxisNumeric
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special183" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MixedSubPlotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special184" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <TableandChartSubplotBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special185" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special186" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special187" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special188" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special1" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special189" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special190" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special191" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special192" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special193" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special194" ? (
            <div style={{ marginTop: -10 }}>
              <div style={{ overflow: "hidden", height: 300 }}>
                <div style={{ marginTop: -50, height: 240 }}>
                  <BindingtoZoomEventsBlock
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                </div>
              </div>
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special195" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special196" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special197" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <FilterBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special198" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <GroupByBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special199" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special200" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special201" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special202" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special203" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special204" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special205" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special208" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special209" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special210" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special211" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special212" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special213" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special214" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special215" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special216" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special219" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special220" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special221" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <LassoSelectionBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special222" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <BasicRangeSlideronTimeSeriesBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special223" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special224" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special225" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special226" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special227" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special228" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special229" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special230" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special231" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special232" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special233" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MapAnimationsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special234" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special235" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special236" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special237" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special238" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special239" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special240" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special241" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special242" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 0 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[0] &&
            this.props.selectedCharts[0].type == "special243" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <AnimatingwithaSliderBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                xName={this.props.columns && this.props.columns[0]}
                yName={this.props.columns && this.props.columns[1]}
                zName={this.props.columns && this.props.columns[2]}
              />
            </div>
          ) : null}
          {/* mm i==0 end */}

          {i == 1 &&
          this.props.selectedCharts &&
          this.props.selectedCharts[1] &&
          this.props.selectedCharts[1].type == "special20" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special21" ? (
            <div style={{ display: "flex", overflow: "hidden", height: 208 }}>
              <div
                style={{
                  marginLeft: -30,
                  overflow: "hidden",
                  height: 400,
                }}
              >
                {this.state.category2 == "special20" ? (
                  <CustomNodeLineandScatterPlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category2 == "special21" ? (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category2 == "special22" ? (
                  <CustomNodeDataLabelsOnThePlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category2 == "special23" ? (
                  <CustomNodeScatterPlotWithColorDimension
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                )}
              </div>
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special22" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special23" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special24" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special25" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special26" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special27" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special28" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special29" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special30" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special31" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special32" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special33" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special34" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special35" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special36" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special37" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special38" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special39" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special40" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special41" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special42" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special43" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special44" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special45" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special46" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special47" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special48" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special49" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special50" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special51" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special52" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special53" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special54" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special55" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special56" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special57" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <CustomNodeCategoricalDotPlot
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special58" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special59" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special60" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special61" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special62" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special63" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special64" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special65" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special66" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special67" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special68" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special69" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special70" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special71" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special72" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category2 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special73" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special74" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special75" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special76" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special77" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special78" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special81" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special82" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special83" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special84" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special85" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special86" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special87" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special88" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special89" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special90" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special91" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special92" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special93" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special94" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special95" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special96" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special97" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special98" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special99" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special100" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special101" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special102" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special103" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special104" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special105" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special106" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special107" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special108" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special109" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special110" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special111" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special112" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special113" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special114" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special115" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special116" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special120" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special121" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special122" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special123" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special124" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special125" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special126" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special127" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category2 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category2 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special128" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category2 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category2 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special129" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category2 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category2 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special130" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category2 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category2 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special131" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category2 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category2 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special132" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special133" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special134" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special135" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special136" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special137" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special138" ? (
            <LogarithmicAxesBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
              IsDataNumeric={
                this.props.xaxisNumeric === true &&
                this.props.yaxisNumeric === true
                  ? true
                  : false
              }
            />
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special139" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special140" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special141" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special142" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special147" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special148" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special149" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special150" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special151" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special152" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special153" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special154" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special155" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special156" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category2 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special157" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special158" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special159" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special160" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special161" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special162" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special163" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special164" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special165" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special166" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special167" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special168" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special169" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special170" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special170" ? (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special171" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special170" ? (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special172" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special170" ? (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special173" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special170" ? (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category2 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special174" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special175" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special176" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special177" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special178" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special179" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special180" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special181" ? (
            <SimpleInsetGraphBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
            />
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special182" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <Multiple3DSubplotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true &&
                  this.props.zaxisNumeric
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special183" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MixedSubPlotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special184" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <TableandChartSubplotBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special185" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special186" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special187" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special188" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special1" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special189" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special190" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special191" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special192" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special193" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special194" ? (
            <div style={{ marginTop: -10 }}>
              <div style={{ overflow: "hidden", height: 300 }}>
                <div style={{ marginTop: -50, height: 240 }}>
                  <BindingtoZoomEventsBlock
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                </div>
              </div>
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special195" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special196" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special197" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <FilterBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special198" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <GroupByBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special199" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special200" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special201" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special202" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special203" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special204" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special205" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special208" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special209" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special210" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special211" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special212" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special213" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special214" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special215" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special216" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special219" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special220" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special221" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <LassoSelectionBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special222" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <BasicRangeSlideronTimeSeriesBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special223" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special224" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special225" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special226" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special227" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special228" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special229" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special230" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special231" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special232" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special233" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MapAnimationsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special234" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicTreemapsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special235" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special236" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special237" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category2 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special238" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special239" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category2 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special240" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special241" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special242" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category2 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category2 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 1 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[1] &&
            this.props.selectedCharts[1].type == "special243" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <AnimatingwithaSliderBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                xName={this.props.columns && this.props.columns[0]}
                yName={this.props.columns && this.props.columns[1]}
                zName={this.props.columns && this.props.columns[2]}
              />
            </div>
          ) : null}
          {/*mm i==1 end*/}

          {i == 2 &&
          this.props.selectedCharts &&
          this.props.selectedCharts[2] &&
          this.props.selectedCharts[2].type == "special20" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special21" ? (
            <div style={{ display: "flex", overflow: "hidden", height: 208 }}>
              <div
                style={{
                  marginLeft: -30,
                  overflow: "hidden",
                  height: 400,
                }}
              >
                {this.state.category3 == "special20" ? (
                  <CustomNodeLineandScatterPlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category3 == "special21" ? (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category3 == "special22" ? (
                  <CustomNodeDataLabelsOnThePlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category3 == "special23" ? (
                  <CustomNodeScatterPlotWithColorDimension
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                )}
              </div>
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special22" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special23" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special24" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special25" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special26" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special27" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special28" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special29" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special30" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special31" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special32" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special33" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special34" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special35" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special36" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special37" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special38" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special39" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special40" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special41" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special42" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special43" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special44" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special45" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special46" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special47" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special48" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special49" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special50" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special51" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special52" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special53" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special54" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special55" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special56" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special57" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <CustomNodeCategoricalDotPlot
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special58" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special59" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special60" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special61" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special62" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special63" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special64" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special65" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special66" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special67" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special68" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special69" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special70" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special71" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special72" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category3 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special73" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special74" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special75" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special76" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special77" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special78" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special81" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special82" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special83" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special84" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special85" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special86" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special87" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special88" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special89" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special90" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special91" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special92" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special93" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special94" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special95" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special96" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special97" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special98" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special99" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special100" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special101" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special102" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special103" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special104" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special105" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special106" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special107" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special108" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special109" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special110" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special111" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special112" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special113" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special114" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special115" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special116" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special120" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special121" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special122" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special123" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special124" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special125" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special126" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special127" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category3 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category3 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special128" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category3 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category3 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special129" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category3 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category3 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special130" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category3 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category3 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special131" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category3 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category3 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special132" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special133" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special134" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special135" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special136" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special137" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special138" ? (
            <LogarithmicAxesBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
              IsDataNumeric={
                this.props.xaxisNumeric === true &&
                this.props.yaxisNumeric === true
                  ? true
                  : false
              }
            />
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special139" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special140" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special141" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special142" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special147" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special148" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special149" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special150" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special151" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special152" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special153" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special154" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special155" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special156" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category3 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special157" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special158" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special159" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special160" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special161" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special162" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special163" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special164" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special165" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special166" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special167" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special168" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special169" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special170" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special171" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special172" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special173" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category3 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special174" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special175" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special176" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special177" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special178" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special179" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special180" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special181" ? (
            <SimpleInsetGraphBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
            />
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special182" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <Multiple3DSubplotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true &&
                  this.props.zaxisNumeric
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special183" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MixedSubPlotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special184" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <TableandChartSubplotBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special185" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special186" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special187" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special188" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special1" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special189" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special190" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special191" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special192" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special193" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special194" ? (
            <div style={{ marginTop: -10 }}>
              <div style={{ overflow: "hidden", height: 300 }}>
                <div style={{ marginTop: -50, height: 240 }}>
                  <BindingtoZoomEventsBlock
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                </div>
              </div>
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special195" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special196" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special197" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <FilterBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special198" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <GroupByBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special199" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special200" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special201" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special202" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special203" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special204" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special205" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special208" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special209" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special210" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special211" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special212" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special213" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special214" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special215" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special216" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special219" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special220" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special221" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <LassoSelectionBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special222" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <BasicRangeSlideronTimeSeriesBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special223" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special224" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special225" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special226" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special227" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special228" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special229" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special230" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special231" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special232" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special233" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MapAnimationsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special234" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special235" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special236" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special237" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category3 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special238" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special239" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category3 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special240" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special241" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special242" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category3 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category3 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 2 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[2] &&
            this.props.selectedCharts[2].type == "special243" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <AnimatingwithaSliderBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                xName={this.props.columns && this.props.columns[0]}
                yName={this.props.columns && this.props.columns[1]}
                zName={this.props.columns && this.props.columns[2]}
              />
            </div>
          ) : null}

          {/*mm i==2 end */}

          {i == 3 &&
          this.props.selectedCharts &&
          this.props.selectedCharts[3] &&
          this.props.selectedCharts[3].type == "special20" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special21" ? (
            <div style={{ display: "flex", overflow: "hidden", height: 208 }}>
              <div
                style={{
                  marginLeft: -30,
                  overflow: "hidden",
                  height: 400,
                }}
              >
                {this.state.category4 == "special20" ? (
                  <CustomNodeLineandScatterPlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category4 == "special21" ? (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category4 == "special22" ? (
                  <CustomNodeDataLabelsOnThePlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category4 == "special23" ? (
                  <CustomNodeScatterPlotWithColorDimension
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                )}
              </div>
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special22" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special23" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special24" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special25" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special26" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special27" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special28" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special29" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special30" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special31" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special32" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special33" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special34" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special35" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special36" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special37" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special38" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special39" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special40" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special41" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special42" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special43" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special44" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special45" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special46" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special47" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special48" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special49" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special50" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special51" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special52" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special53" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special54" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special55" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special56" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special57" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <CustomNodeCategoricalDotPlot
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special58" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special59" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special60" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special61" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special62" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special63" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special64" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special65" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special66" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special67" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special68" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special69" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special70" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special71" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special72" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category4 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special73" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special74" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special75" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special76" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special77" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special78" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special81" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special82" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special83" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special84" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special85" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special86" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special87" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special88" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special89" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special90" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special91" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special92" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special93" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special94" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special95" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special96" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special97" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special98" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special99" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special100" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special101" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special102" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special103" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special104" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special105" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special106" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special107" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special108" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special109" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special110" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special111" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special112" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special113" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special114" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special115" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special116" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special120" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special121" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special122" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special123" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special124" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special125" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special126" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special127" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category4 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category4 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special128" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category4 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category4 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special129" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category4 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category4 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special130" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category4 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category4 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special131" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category4 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category4 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special132" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special133" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special134" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special135" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special136" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special137" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special138" ? (
            <LogarithmicAxesBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
              IsDataNumeric={
                this.props.xaxisNumeric === true &&
                this.props.yaxisNumeric === true
                  ? true
                  : false
              }
            />
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special139" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special140" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special141" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special142" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special147" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special148" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special149" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special150" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special151" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special152" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special153" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special154" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special155" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special156" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category4 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special157" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special158" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special159" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special160" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special161" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special162" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special163" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special164" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special165" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special166" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special167" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special168" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special169" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special170" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special171" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special172" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special173" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category4 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special174" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special175" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special176" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special177" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special178" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special179" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special180" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special181" ? (
            <SimpleInsetGraphBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
            />
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special182" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <Multiple3DSubplotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true &&
                  this.props.zaxisNumeric
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special183" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MixedSubPlotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special184" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <TableandChartSubplotBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special185" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special186" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special187" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special188" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special1" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special189" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special190" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special191" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special192" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special193" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special194" ? (
            <div style={{ marginTop: -10 }}>
              <div style={{ overflow: "hidden", height: 300 }}>
                <div style={{ marginTop: -50, height: 240 }}>
                  <BindingtoZoomEventsBlock
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                </div>
              </div>
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special195" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special196" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special197" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <FilterBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special198" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <GroupByBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special199" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special200" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special201" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special202" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special203" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special204" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special205" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special208" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special209" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special210" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special211" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special212" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special213" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special214" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special215" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special216" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special219" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special220" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special221" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <LassoSelectionBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special222" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <BasicRangeSlideronTimeSeriesBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special223" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special224" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special225" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special226" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special227" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special228" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special229" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special230" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special231" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special232" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special233" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MapAnimationsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special234" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special235" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special236" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special237" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category4 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special238" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special239" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category4 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special240" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special241" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special242" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category4 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category4 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 3 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[3] &&
            this.props.selectedCharts[3].type == "special243" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <AnimatingwithaSliderBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                xName={this.props.columns && this.props.columns[0]}
                yName={this.props.columns && this.props.columns[1]}
                zName={this.props.columns && this.props.columns[2]}
              />
            </div>
          ) : null}

          {/*mm i==3 end*/}

          {i == 4 &&
          this.props.selectedCharts &&
          this.props.selectedCharts[4] &&
          this.props.selectedCharts[4].type == "special20" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special21" ? (
            <div style={{ display: "flex", overflow: "hidden", height: 208 }}>
              <div
                style={{
                  marginLeft: -30,
                  overflow: "hidden",
                  height: 400,
                }}
              >
                {this.state.category5 == "special20" ? (
                  <CustomNodeLineandScatterPlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category5 == "special21" ? (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category5 == "special22" ? (
                  <CustomNodeDataLabelsOnThePlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category5 == "special23" ? (
                  <CustomNodeScatterPlotWithColorDimension
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                )}
              </div>
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special22" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special23" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special24" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special25" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special26" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special27" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special28" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special29" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special30" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special31" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special32" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special33" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special34" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special35" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special36" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special37" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special38" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special39" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special40" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special41" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special42" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special43" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special44" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special45" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special46" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special47" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special48" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special49" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special50" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special51" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special52" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special53" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special54" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special55" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special56" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special57" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <CustomNodeCategoricalDotPlot
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special58" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special59" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special60" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special61" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special62" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special63" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special64" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special65" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special66" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special67" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special68" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special69" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special70" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special71" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special72" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category5 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special73" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special74" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special75" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special76" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special77" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special78" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special81" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special82" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special83" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special84" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special85" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special86" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special87" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special88" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special89" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special90" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special91" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special92" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special93" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special94" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special95" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special96" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special97" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special98" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special99" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special100" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special101" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special102" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special103" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special104" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special105" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special106" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special107" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special108" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special109" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special110" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special111" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special112" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special113" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special114" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special115" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special116" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special120" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special121" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special122" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special123" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special124" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special125" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special126" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special127" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category5 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category5 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special128" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category5 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category5 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special129" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category5 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category5 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special130" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category5 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category5 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special131" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category5 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category5 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special132" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special133" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special134" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special135" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special136" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special137" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special138" ? (
            <LogarithmicAxesBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
              IsDataNumeric={
                this.props.xaxisNumeric === true &&
                this.props.yaxisNumeric === true
                  ? true
                  : false
              }
            />
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special139" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special140" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special141" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special142" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special147" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special148" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special149" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special150" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special151" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special152" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special153" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special154" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special155" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special156" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category5 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special157" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special158" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special159" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special160" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special161" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special162" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special163" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special164" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special165" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special166" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special167" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special168" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special169" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special170" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special171" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special172" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special173" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category5 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special174" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special175" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special176" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special177" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special178" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special179" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special180" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special181" ? (
            <SimpleInsetGraphBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
            />
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special182" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <Multiple3DSubplotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true &&
                  this.props.zaxisNumeric
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special183" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MixedSubPlotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special184" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <TableandChartSubplotBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special185" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special186" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special187" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special188" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special1" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special189" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special190" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special191" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special192" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special193" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special194" ? (
            <div style={{ marginTop: -10 }}>
              <div style={{ overflow: "hidden", height: 300 }}>
                <div style={{ marginTop: -50, height: 240 }}>
                  <BindingtoZoomEventsBlock
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                </div>
              </div>
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special195" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special196" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special197" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <FilterBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special198" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <GroupByBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special199" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special200" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special201" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special202" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special203" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special204" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special205" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special208" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special209" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special210" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special211" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special212" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special213" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special214" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special215" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special216" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special219" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special220" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special221" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <LassoSelectionBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special222" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <BasicRangeSlideronTimeSeriesBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special223" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special224" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special225" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special226" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special227" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special228" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special229" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special230" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special231" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special232" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special233" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MapAnimationsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special234" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special235" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special236" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special237" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category5 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special238" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special239" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category5 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special240" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special241" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special242" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category5 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category5 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 4 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[4] &&
            this.props.selectedCharts[4].type == "special243" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <AnimatingwithaSliderBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                xName={this.props.columns && this.props.columns[0]}
                yName={this.props.columns && this.props.columns[1]}
                zName={this.props.columns && this.props.columns[2]}
              />
            </div>
          ) : null}

          {/*mm i==4 end */}

          {i == 5 &&
          this.props.selectedCharts &&
          this.props.selectedCharts[5] &&
          this.props.selectedCharts[5].type == "special20" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special21" ? (
            <div style={{ display: "flex", overflow: "hidden", height: 208 }}>
              <div
                style={{
                  marginLeft: -30,
                  overflow: "hidden",
                  height: 400,
                }}
              >
                {this.state.category6 == "special20" ? (
                  <CustomNodeLineandScatterPlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category6 == "special21" ? (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category6 == "special22" ? (
                  <CustomNodeDataLabelsOnThePlot
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : this.state.category6 == "special23" ? (
                  <CustomNodeScatterPlotWithColorDimension
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                ) : (
                  <CustomNodeDataLabelsHover
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                )}
              </div>
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special22" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special23" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special20" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special23" ? (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special21" ? (
                <CustomNodeDataLabelsHover
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special22" ? (
                <CustomNodeDataLabelsOnThePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeScatterPlotWithColorDimension
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special24" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special25" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special26" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special27" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special28" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special29" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special30" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special31" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special32" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special33" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special24" ? (
                <CustomNodeBasicLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special25" ? (
                <CustomNodeLineandScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special26" ? (
                <CustomNodeLineandScatterPlotWithNames
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special27" ? (
                <CustomLineandScatterStyling
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special28" ? (
                <CustomNodeStyledLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special29" ? (
                <CustomNodeColoredandStyledScatterPlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special30" ? (
                <CustomNodeLineShapeOptionsforInterpolation
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special31" ? (
                <CustomNodeGraphandAxesTitles
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special32" ? (
                <CustomNodeLineDash
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special33" ? (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeConnectGapsBetweenData
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special34" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special35" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special36" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special37" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special38" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special39" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special40" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special41" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special42" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special43" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special44" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special45" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special46" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special34" ? (
                <CustomNodeBasicBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special35" ? (
                <CustomNodeGroupedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special36" ? (
                <CustomNodeStackedBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special37" ? (
                <CustomNodeBarChartwithHoverText
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special38" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special39" ? (
                <CustomNodeGroupedBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special40" ? (
                <CustomNodeBarChartwithDirectLabels
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special41" ? (
                <CustomNodeCustomizingIndividualBarColors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special42" ? (
                <CustomNodeCustomizingIndividualBarWidths
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special43" ? (
                <CustomNodeCustomizingIndividualBarBase
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special44" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special45" ? (
                <CustomNodeWaterfallBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special46" ? (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithRelativeBarmode
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special47" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special48" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special49" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special50" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special51" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special47" ? (
                <CustomNodeBasicPieChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special48" ? (
                <CustomNodePieChartSubplots
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special49" ? (
                <CustomNodeDonutChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special50" ? (
                <CustomNodeAutomaticallyAdjustMargin
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special51" ? (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : (
                <ControlTextOrientationInsidePieChartSectors
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special52" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special53" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special54" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special55" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special56" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special52" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special53" ? (
                <CustomNodeMarkerSizeandColorBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special54" ? (
                <CustomNodeHoverTextonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special55" ? (
                <CustomNodeBubbleSizeonBubbleChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special56" ? (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeMarkerSizeColorandSymbolasArray
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special57" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <CustomNodeCategoricalDotPlot
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special58" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special59" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special60" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special61" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special62" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special58" ? (
                <CustomNodeBasicOverlaidAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special59" ? (
                <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special60" ? (
                <CustomNodeStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special61" ? (
                <CustomNodeNormalizedStackedAreaChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special62" ? (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeSelectHoverPoints
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special63" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special64" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special65" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special63" ? (
                <CustomNodeBasicHorizontalBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special64" ? (
                <CustomNodeColoredandStyledBarChart
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special65" ? (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomNodeBarChartwithLinePlot
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special66" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special67" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special66" ? (
                <CustomNodeBasicPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special67" ? (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomNodeStyledPointCloud
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special68" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special69" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special70" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special71" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special72" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special68" ? (
                <BasicSunBurstChart
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special69" ? (
                <BranchValues
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special70" ? (
                <SunburstWithRepetedLabels
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special71" ? (
                <SunburstLargeNumberSlices
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : this.state.category6 == "special72" ? (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SunburstTextOrientaion
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special73" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special74" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special75" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special76" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special77" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special78" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special73" ? (
                <SankeyDiagrams
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special74" ? (
                <CreateSankeyCanvas
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special75" ? (
                <AddNodesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special76" ? (
                <AddLinksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special77" ? (
                <StyleSankeyDiagramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric == true &&
                    this.props.yaxisNumeric == true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special78" ? (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefineNodePositionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special81" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special82" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special83" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special84" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special85" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special86" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special87" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special81" ? (
                <BasicSymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special82" ? (
                <BarChartwithErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special83" ? (
                <HorizontalErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special84" ? (
                <AsymmetricErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special85" ? (
                <ColoredandStyledErrorBarsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special86" ? (
                <ErrorBarsasaPercentageoftheyValueBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special87" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special88" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special89" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special90" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special91" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special92" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special93" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special94" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special95" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special96" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special97" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <BasicBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special89" ? (
                <BoxPlotThatDisplayesUnderlyingdataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <HorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special91" ? (
                <GroupedBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === false &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special92" ? (
                <BoxPlotStylingOutliersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special93" ? (
                <BoxPlotStylingMeanandStandardDeviationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special94" ? (
                <GroupedHorizontalBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special95" ? (
                <ColoredBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special96" ? (
                <FullyStyledBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special97" ? (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <RainBowBoxPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special98" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special99" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special100" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special101" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special102" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special103" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special104" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special105" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special98" ? (
                <BasicHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special99" ? (
                <HorizontalHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special100" ? (
                <OverlaidHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special101" ? (
                <StackedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special102" ? (
                <ColoredandStyledHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special103" ? (
                <CumulativeHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special104" ? (
                <NormalizedHistogramBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special105" ? (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SpecifyBinningFunctionBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special106" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special107" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special106" ? (
                <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special107" ? (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TwoDHistogramContourPlotwithSliderControlBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special108" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special109" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special108" ? (
                <FilledLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special109" ? (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AsymmetricErrorBarswithaConstantOffsetBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special110" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special111" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special112" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special113" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special114" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special115" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special116" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special120" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special121" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special122" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special123" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special124" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special125" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special126" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special127" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special110" ? (
                <SimpleContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special111" ? (
                <BasicContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special112" ? (
                <SettingXandYCoordinatesinaContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : this.state.category6 == "special113" ? (
                <ColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special114" ? (
                <CustomizingSizeandRangeofaContourPlotContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special115" ? (
                <CustomizingSpacingBetweenXandYTicksBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special116" ? (
                <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special120" ? (
                <SmoothingContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special121" ? (
                <SmoothContourColoringBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special122" ? (
                <ContourLinesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special123" ? (
                <ContourLineLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special124" ? (
                <CustomColorscaleforContourPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special125" ? (
                <ColorBarTitleBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special126" ? (
                <ColorBarSizeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : this.state.category6 == "special127" ? (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              ) : (
                <StylingColorBarTicksforContourPlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box1}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special128" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category6 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category6 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special129" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category6 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category6 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special130" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category6 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category6 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special131" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special128" ? (
                <BasicHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                  box={this.props.box}
                />
              ) : this.state.category6 == "special129" ? (
                <HeatmapwithCategoricalAxisLabelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box4}
                />
              ) : this.state.category6 == "special130" ? (
                <AnnotatedHeatmapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special131" ? (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              ) : (
                <HeatmapwithUnequalBlockSizesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                  box={this.props.box5}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special132" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special133" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special132" ? (
                <BasicTernaryPlotwithMarkersBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special133" ? (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SoilTypesTernaryPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special134" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special135" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special136" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special137" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special134" ? (
                <AddingDimensionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special135" ? (
                <BasicParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special136" ? (
                <AnnotatedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special137" ? (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AdvancedParallelCoordinatesPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special138" ? (
            <LogarithmicAxesBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
              IsDataNumeric={
                this.props.xaxisNumeric === true &&
                this.props.yaxisNumeric === true
                  ? true
                  : false
              }
            />
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special139" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special140" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special141" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special142" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special142" ? (
                <StyleWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special139" ? (
                <BasicWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special140" ? (
                <MultiCategoryWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special141" ? (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HorizontalWaterfallChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special147" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special148" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special149" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special150" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special151" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special147" ? (
                <SimpleCandleStickChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special148" ? (
                <CandlestickChartwithoutRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special149" ? (
                <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special150" ? (
                <CustomizingCandlestickChartColorsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special151" ? (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddRangeselectorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special152" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special153" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special154" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special155" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special156" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special152" ? (
                <BasicFunnelPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special153" ? (
                <SettingMarkerSizeandColorBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special154" ? (
                <StackedFunnelBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special155" ? (
                <FunnelareaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true ? true : false
                  }
                />
              ) : this.state.category6 == "special156" ? (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <MultiFunnelareaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special157" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special158" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special159" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special160" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special157" ? (
                <DateStringsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special158" ? (
                <BasicTimeSeriesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special159" ? (
                <ManuallySetRangeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special160" ? (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TimeSerieswithRangesliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special161" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special162" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special163" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special164" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special165" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special161" ? (
                <ThreeDScatterPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special162" ? (
                <BasicRibbonPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special163" ? (
                <Topographical3DSurfacePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special164" ? (
                <SurfacePlotWithContoursBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special165" ? (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <Multiple3DSurfacePlotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special166" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special167" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special168" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special169" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special166" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special167" ? (
                <ThreeDMeshPlotwithAlphahullBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special168" ? (
                <ThreeDMeshTetrahedronBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special169" ? (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <ThreeDMeshCubeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special170" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinePlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special171" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special172" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special173" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special70" ? (
                <Simple3DMeshPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric === true
                      ? true
                      : false
                  }
                  xbox={this.props.xbox}
                  ybox={this.props.ybox}
                  zbox={this.props.zbox}
                />
              ) : this.state.category6 == "special171" ? (
                <ThreeDLinewithMarkersPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special172" ? (
                <ThreeDLineSpiralPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special173" ? (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ThreeDRandomWalkPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special174" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special175" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special176" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special177" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special178" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special179" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special180" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special74" ? (
                <SimpleSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special175" ? (
                <CustomSizedSubplotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special176" ? (
                <MultipleSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special177" ? (
                <SubplotswithSharedAxesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special178" ? (
                <StackedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special179" ? (
                <StackedSubplotswithaSharedXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special180" ? (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleCustomSizedSubplotsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special181" ? (
            <SimpleInsetGraphBlock
              block={false}
              data={this.props.data2}
              x={this.props.x}
              y={this.props.y}
              z={this.props.z}
            />
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special182" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <Multiple3DSubplotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true &&
                  this.props.zaxisNumeric
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special183" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MixedSubPlotsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special184" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <TableandChartSubplotBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special185" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special186" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special187" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special85" ? (
                <ClickEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special186" ? (
                <BindingToClickEventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special187" ? (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CreateannotationonclickeventBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special188" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special1" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special189" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special190" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special191" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special192" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special193" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special88" ? (
                <HoverEventDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special189" ? (
                <CapturingHoverEventsDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special90" ? (
                <CapturingHoverEventsPixelsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special191" ? (
                <TriggeringHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special192" ? (
                <CoupledHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special193" ? (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <CombinedClickandHoverEventsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special194" ? (
            <div style={{ marginTop: -10 }}>
              <div style={{ overflow: "hidden", height: 300 }}>
                <div style={{ marginTop: -50, height: 240 }}>
                  <BindingtoZoomEventsBlock
                    block={false}
                    data={this.props.data2}
                    x={this.props.x}
                    y={this.props.y}
                    z={this.props.z}
                    xName={this.props.columns && this.props.columns[0]}
                    yName={this.props.columns && this.props.columns[1]}
                    zName={this.props.columns && this.props.columns[2]}
                  />
                </div>
              </div>
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special195" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special196" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special95" ? (
                <DisablingZoomEventsforXAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special196" ? (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DisablingZoomEventsforXandYAxisBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special197" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <FilterBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                IsDataNumeric={
                  this.props.xaxisNumeric === true &&
                  this.props.yaxisNumeric === true
                    ? true
                    : false
                }
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special198" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <GroupByBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special199" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special200" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special201" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special202" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special199" ? (
                <AggregationsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special200" ? (
                <AggregateFunctionsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special201" ? (
                <HistogramBinningBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special202" ? (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MappingWithAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special203" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special204" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special205" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia203" ? (
                <FilterAndGroupbyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special204" ? (
                <FilterAndAggregatesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special205" ? (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AllTransformsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special208" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special209" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia208" ? (
                <AddTwoDropdownMenustoaChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special209" ? (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BinddropdowneventstochartsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special210" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special211" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special212" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special213" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special214" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special215" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia210" ? (
                <RestyleButtonSingleAttributeBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special211" ? (
                <RestyleButtonMultipleAttributesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special212" ? (
                <RelayoutButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special213" ? (
                <UpdateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special214" ? (
                <AnimateButtonBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special215" ? (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              ) : (
                <StylethebuttonsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  box={this.props.box1}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special216" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special219" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special220" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia216" ? (
                <BasicSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special219" ? (
                <BindComponentstotheAppearanceofaPlotBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special220" ? (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AddaPlayButtontoControlaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special221" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <LassoSelectionBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special222" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <BasicRangeSlideronTimeSeriesBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special223" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special224" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special225" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special226" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special227" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special228" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special229" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special230" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia223" ? (
                <AnimatingtheDataBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special224" ? (
                <AnimatingtheLayoutBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special225" ? (
                <DefiningNamedFramesaddFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special226" ? (
                <AnimatingSequencesofFramesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special227" ? (
                <AnimatingManyFramesQuicklyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special228" ? (
                <ObjectConstancyBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special229" ? (
                <FrameGroupsandAnimationModesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special230" ? (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <AnimatingwithaSliderBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special231" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special232" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special231" ? (
                <FilledAreaAnimationBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special232" ? (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <MultipleTraceFilledAreaBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special233" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <MapAnimationsBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
              />
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special234" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special235" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special236" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special237" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special234" ? (
                <BasicTreemapsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special235" ? (
                <SetDifferentAttributesinTreeMapBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special236" ? (
                <SetColorofTreemapSectorsBlock
                  block={false}
                  data2={this.props.data4}
                  data={this.props.data2}
                />
              ) : this.state.category6 == "special237" ? (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              ) : (
                <NestedLayersinTreemapBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  data2={this.props.data4}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special238" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special239" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "specia238" ? (
                <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                  IsDataNumeric={
                    this.props.xaxisNumeric === true &&
                    this.props.yaxisNumeric === true &&
                    this.props.zaxisNumeric
                      ? true
                      : false
                  }
                />
              ) : this.state.category6 == "special239" ? (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <LineChartandBarChartBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special240" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special241" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special242" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              {this.state.category6 == "special240" ? (
                <WebGLwithOneLakhpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special241" ? (
                <WebGLwithOneMillionpointsBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : this.state.category6 == "special242" ? (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              ) : (
                <WebGLwithmanytracesBlock
                  block={false}
                  data={this.props.data2}
                  x={this.props.x}
                  y={this.props.y}
                  z={this.props.z}
                  xName={this.props.columns && this.props.columns[0]}
                  yName={this.props.columns && this.props.columns[1]}
                  zName={this.props.columns && this.props.columns[2]}
                />
              )}
            </div>
          ) : i == 5 &&
            this.props.selectedCharts &&
            this.props.selectedCharts[5] &&
            this.props.selectedCharts[5].type == "special243" ? (
            <div
              style={{
                marginLeft: -20,
                overflow: "hidden",
                height: 400,
              }}
            >
              <AnimatingwithaSliderBlock
                block={false}
                data={this.props.data2}
                x={this.props.x}
                y={this.props.y}
                z={this.props.z}
                xName={this.props.columns && this.props.columns[0]}
                yName={this.props.columns && this.props.columns[1]}
                zName={this.props.columns && this.props.columns[2]}
              />
            </div>
          ) : null}

          {/*mm i==5 end*/}
        </div>
      </div>
    );
  }

  onAddItem() {
    /*eslint no-console: 0*/
    // console.log('adding', 'n' + this.state.newCounter)
    // console.log('CH...' + this.state.newCounter)
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: "n" + this.state.newCounter,
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 4,
        h: 3,
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1,
    });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols,
    });
  }

  onRemoveItem(i) {
    //  console.log('removing', i)
    this.setState({ items: _.reject(this.state.items, { i: i }) });
  }

  render() {
    return (
      <div>
        {/* <button onClick={this.onAddItem}>Add Widget</button> */}

        {this.state.isExport && (
          <Modal
            open={this.state.isExport}
            onClose={() => this.setState({ isExport: false })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={exportModalstyle}>
              <>
                <CloseIcon
                  onClick={() => {
                    if (this.state.isExportCSV) {
                      //  exportCSV()
                      this.setState({ isExportCSV: false });
                    }
                    if (this.state.isExportPDF) {
                      this.setState({ isExportPDF: false });
                    }
                    if (this.state.isExportHTML) {
                      this.setState({ isExportHTML: false });
                    }
                    this.setState({ clickedExport: false });
                    this.setState({ isExport: false });
                  }}
                  style={{
                    position: "absolute",
                    left: "95%",
                    top: "1%",
                    cursor: "pointer",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 20,
                      marginTop: 20,
                      fontSize: 16,
                      fontFamily: "Trebuchet MS",
                    }}
                  >
                    File Name
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <input
                      style={{
                        marginTop: 5,
                        marginLeft: 20,
                        width: 350,
                        height: 40,
                        border: "1px solid #CCC",
                        borderRadius: 5,
                      }}
                      type={"text"}
                      placeholder={"Add File Name.."}
                      value={this.state.fileName}
                      onChange={(e) =>
                        this.setState({ fileName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 25,
                    display: "flex",
                    alignSelf: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ marginRight: 10, marginBottom: 20 }}>
                    <Button
                      sx={{
                        bgcolor: "#067AB4",
                        color: "#CCC",
                        fontFamily: "Trebuchet MS",
                      }}
                      onClick={() => {
                        if (this.state.isExportCSV) {
                          this.setState({ isExportCSV: false });
                        }
                        if (this.state.isExportPDF) {
                          this.setState({ isExportPDF: false });
                        }
                        if (this.state.isExportHTML) {
                          this.setState({ isExportHTML: false });
                        }
                        this.setState({ clickedExport: false });
                        this.setState({ isExport: false });
                      }}
                      variant="outlined"
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginRight: 20, marginBottom: 20 }}>
                    <Pdf
                      targetRef={this.ref2}
                      filename={this.state.fileName}
                      options={options}
                    >
                      {({ toPdf }) => (
                        <Button
                          sx={{
                            bgcolor: "#067AB4",
                            color: "white",
                            "&:hover, &:focus": {
                              bgcolor: "#0BAFFF",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            if (this.state.isExportCSV) {
                              this.exportCSV();
                              this.setState({ isExportCSV: false });
                            }
                            if (this.state.isExportPDF) {
                              toPdf();

                              this.setState({ isExportPDF: false });
                            }
                            if (this.state.isExportHTML) {
                              this.exportToHtml();
                              this.setState({ isExportHTML: false });
                            }
                            setTimeout(() => {
                              this.setState({ clickedExport: false });
                              this.setState({ fileName: "" });
                            }, 1200);
                            this.setState({ isExport: false });
                          }}
                          variant="outlined"
                        >
                          {this.state.isExportCSV
                            ? "Export To CSV"
                            : this.state.isExportPDF
                            ? "Export To PDF"
                            : this.state.isExportHTML
                            ? "Export To HTML"
                            : "Export"}
                        </Button>
                      )}
                    </Pdf>
                  </div>
                </div>
              </>
            </Box>
          </Modal>
        )}

        {this.props?.fromFlow == true && (
          <div
            style={{
              marginTop: -230,
              justifyContent: "left",
              marginLeft: "30px",
              justifyContent: "left",
            }}
          >
            <h2
              style={{
                fontWeight: "bold",
                display: "block",
                width: "100%",
                textAlign: "left",
              }}
            >
              Insights
            </h2>
            {/* <p
              style={{
                display: 'block',
                width: '100%',
                justifyContent: 'left',
                textAlign: 'left',
                fontFamily: 'Trebuchet MS'
              }}
            >
              Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
              Ipsum Lorem Ipsum Lorem Ipsum
            </p> */}
          </div>
        )}

        {this.props?.fromFlow != true && (
          <div
            style={{
              marginTop: -85,
              justifyContent: "left",
              marginLeft: "30px",
              justifyContent: "left",
            }}
          >
            <h2
              style={{
                fontWeight: "bold",
                display: "block",
                width: "100%",
                textAlign: "left",
                fontFamily: "Trebuchet MS",
              }}
            >
              ANALYTICS DASHBOARD
            </h2>
            {/* <p
              style={{
                display: 'block',
                width: '100%',
                justifyContent: 'left',
                textAlign: 'left',
                fontFamily: 'Trebuchet MS'
              }}
            >
              Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem
              Ipsum Lorem Ipsum Lorem Ipsum
            </p> */}
          </div>
        )}

        {/* {this.props?.fromFlow != true && (
          <div style={{ position: 'absolute', marginTop: 38, left: 40 }}>
            <Box sx={{ mt: 6, ml: 3, height: 40 }}>
              <StyledSpeedDial
                ariaLabel='SpeedDial playground example'
                hidden={false}
                icon={<SpeedDialIcon />}
                direction={'right'}
                FabProps={{
                  sx: {
                    bgcolor: '#0c0c0c',
                    '&:hover': {
                      bgcolor: '#3480a8'
                    }
                  }
                }}
              >
                {actions.map(action => (
                  <SpeedDialAction
                    //  sx={{ bgcolor: '#0c0c0c', color: 'white' }}
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    FabProps={{
                      sx: {
                        bgcolor: '#0c0c0c',
                        color: 'white',
                        '&:hover': {
                          bgcolor: '#18485c'
                        }
                      }
                    }}
                  />
                ))}
              </StyledSpeedDial>
            </Box>
          </div>
        )} */}

        <FormGroup style={{ width: 220 }}>
          <FormControlLabel
            style={{ marginLeft: 20, marginTop: 2 }}
            control={
              <Switch
                checked={this.state.dragMode}
                onChange={() =>
                  this.setState({ dragMode: !this.state.dragMode })
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              this.state.dragMode == true ? "Drag enabled" : "Drag disabled"
            }
          />
        </FormGroup>
        <CSVLink
          data={this.props.data2}
          filename={this.state.fileName}
          ref={this.csvLink}
          target="_blank"
        ></CSVLink>

        <div
          style={{
            position: "absolute",
            marginTop: this.props?.fromFlow ? -98 : -100,
            right: 50,
            // marginLeft: '72%'
          }}
        >
          <IconButton
            color="primary"
            aria-label="add an alarm"
            onClick={() => {
              this.setState({ clickedExport: true });
              setTimeout(() => this.printDiv(), 1000);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PrintIcon sx={{ color: "#0BAFFF" }} />
              <label style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}>
                Print
              </label>
            </div>
          </IconButton>

          <IconButton
            aria-label="delete"
            onClick={() => {
              this.setState({ clickedExport: true });
              this.setState({ isExportCSV: true });
              this.setState({ isExport: true });
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TableRows sx={{ color: "#0BAFFF" }} />
              <label style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}>
                Export to CSV
              </label>
            </div>
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="add an alarm"
            onClick={() => {
              this.setState({ clickedExport: true });

              this.setState({ isExportPDF: true });
              this.setState({ isExport: true });
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PictureAsPdf sx={{ color: "#0BAFFF" }} />
              <label style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}>
                Export to PDF
              </label>
            </div>
          </IconButton>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => {
              this.setState({ clickedExport: true });
              this.setState({ isExportHTML: true });
              this.setState({ isExport: true });
              //flag == 1 && this.exportToHtml()
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IntegrationInstructions sx={{ color: "#0BAFFF" }} />
              <label style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}>
                Export to HTML
              </label>
            </div>
          </IconButton>
        </div>

        <div
          style={{ marginTop: this.props?.fromFlow == true ? 16 : 75 }}
          ref={this.ref2}
          id="capture"
        >
          <div ref={this.ref3}>
            <ResponsiveReactGridLayout
              // onLayoutChange={this.onLayoutChange}
              // layouts={{ lg: layout }}
              isDraggable={this.state.dragMode == true ? true : false}
              onBreakpointChange={this.onBreakpointChange}
              {...this.props}
            >
              {_.map(this.state.items, (el) => this.createElement(el))}
            </ResponsiveReactGridLayout>
          </div>
          <div
            style={{
              marginTop: 30,
              width: "100%",
              // alignSelf: 'flex-start',
              // display: 'flex',
            }}
          >
            <MyComponent
              rowsData={this.state.rowsData}
              columnData={this.state.columnData}
            />
          </div>
        </div>
      </div>
    );
  }
}

// if (process.env.STATIC_EXAMPLES === true) {
//   import("../test-hook.jsx").then(fn => fn.default(classComp));
// }
