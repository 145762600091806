export const elementsCustom = [
  {
    id: '2',
    type: 'special',
    position: { x: 60, y: 100 },
    isHidden: true,
    data: { text: 'Input FIle Node' }
  },
  {
    id: '3',
    type: 'special2',
    isHidden: true,
    position: { x: 500, y: 100 },
    data: { text: 'COpy Paste Node' }
  },
  {
    id: '4',
    type: 'special3',
    isHidden: true,
    position: { x: 100, y: 220 },
    data: { text: 'Select DataSource' }
  },
  {
    id: '5',
    type: 'special4',
    isHidden: true,
    position: { x: 850, y: 0 },
    data: { text: 'Url Node' }
  },
  {
    id: '6',
    type: 'special5',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Filter Node' }
  },
  {
    id: '7',
    type: 'special6',
    isHidden: true,
    position: { x: 350, y: 70 },
    data: { text: 'Merge Node' }
  },
  {
    id: '8',
    type: 'special7',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Group Node' }
  },
  {
    id: '9',
    type: 'special8',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Slice Node' }
  },
  {
    id: '10',
    type: 'special9',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Sort Node' }
  },
  {
    id: '11',
    type: 'special10',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Rename Column Node' }
  },
  {
    id: '12',
    type: 'special11',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Example Data Node' }
  },
  {
    id: '13',
    type: 'special12',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Histogram Node' }
  },
  {
    id: '14',
    type: 'special13',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Scatterplot Node' }
  },
  {
    id: '15',
    type: 'special14',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Timeseries Node' }
  },
  {
    id: '16',
    type: 'special15',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Stats Node' }
  },
  {
    id: '17',
    type: 'special16',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '20',
    type: 'special20',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '21',
    type: 'special21',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '22',
    type: 'special22',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '23',
    type: 'special23',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '24',
    type: 'special24',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '25',
    type: 'special25',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '26',
    type: 'special26',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '27',
    type: 'special27',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '28',
    type: 'special28',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '29',
    type: 'special29',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '30',
    type: 'special30',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '31',
    type: 'special31',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '32',
    type: 'special32',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '33',
    type: 'special33',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '34',
    type: 'special34',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '35',
    type: 'special35',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '36',
    type: 'special36',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '37',
    type: 'special37',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '38',
    type: 'special38',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '39',
    type: 'special39',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '40',
    type: 'special40',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '41',
    type: 'special41',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '42',
    type: 'special42',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '43',
    type: 'special43',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '44',
    type: 'special44',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '45',
    type: 'special45',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '46',
    type: 'special46',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '47',
    type: 'special47',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '48',
    type: 'special48',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '49',
    type: 'special49',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '50',
    type: 'special50',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '51',
    type: 'special51',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '52',
    type: 'special52',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '53',
    type: 'special53',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '54',
    type: 'special54',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '55',
    type: 'special55',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '56',
    type: 'special56',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },

  {
    id: '57',
    type: 'special57',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '58',
    type: 'special58',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '59',
    type: 'special59',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '60',
    type: 'special60',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '61',
    type: 'special61',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '62',
    type: 'special62',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '63',
    type: 'special63',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '64',
    type: 'special64',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '65',
    type: 'special65',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '66',
    type: 'special66',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '67',
    type: 'special67',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '68',
    type: 'special68',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Basic Sunburst chart' }
  },
  {
    id: '69',
    type: 'special69',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Branch Values' }
  },
  {
    id: '70',
    type: 'special70',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Sunburst with Repeated Labels' }
  },
  {
    id: '71',
    type: 'special71',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Large Number of Slices' }
  },
  {
    id: '72',
    type: 'special72',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Control Text Orientation Inside Sunburst Chart Sectors' }
  },
  {
    id: '73',
    type: 'special73',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Sankey Diagrams' }
  },
  {
    id: '74',
    type: 'special74',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '75',
    type: 'special75',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '76',
    type: 'special76',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '77',
    type: 'special77',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '78',
    type: 'special78',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '234',
    type: 'special234',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '235',
    type: 'special235',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '236',
    type: 'special236',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '237',
    type: 'special237',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '238',
    type: 'special238',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '239',
    type: 'special239',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '240',
    type: 'special240',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '241',
    type: 'special241',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  {
    id: '242',
    type: 'special242',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: '' }
  },
  // { id: '81', type: 'special81', isHidden: true, position: { x: 350, y: 0 }, data: { text: 'Merge Node' } },
  {
    id: '381',
    type: 'special381',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Record Merge' }
  },
  {
    id: '382',
    type: 'special382',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Duplicate Check' }
  },
  {
    id: '383',
    type: 'special383',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Fuzzy Search' }
  },
  {
    id: '384',
    type: 'special384',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Standardization' }
  },
  {
    id: '385',
    type: 'special385',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Replace Null' }
  },
  {
    id: '386',
    type: 'special386',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'InComplete' }
  },
  {
    id: '387',
    type: 'special387',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Formatted' }
  },
  {
    id: '388',
    type: 'special388',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Case Format' }
  },
  {
    id: '389',
    type: 'special389',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Discreet Range' }
  },

  {
    id: '390',
    type: 'special390',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Number Profiler' }
  },

  {
    id: '81',
    type: 'special81',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '82',
    type: 'special82',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },

  {
    id: '83',
    type: 'special83',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '84',
    type: 'special84',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '85',
    type: 'special85',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '86',
    type: 'special86',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '87',
    type: 'special87',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '88',
    type: 'special88',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '89',
    type: 'special89',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '90',
    type: 'special90',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '91',
    type: 'special91',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '92',
    type: 'special92',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '93',
    type: 'special93',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '94',
    type: 'special94',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '95',
    type: 'special95',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '96',
    type: 'special96',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '97',
    type: 'special97',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '98',
    type: 'special98',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '99',
    type: 'special99',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '100',
    type: 'special100',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '101',
    type: 'special101',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '102',
    type: 'special102',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '103',
    type: 'special103',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '104',
    type: 'special104',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '105',
    type: 'special105',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '106',
    type: 'special106',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '107',
    type: 'special107',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '108',
    type: 'special108',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '109',
    type: 'special109',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '110',
    type: 'special110',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '111',
    type: 'special111',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '112',
    type: 'special112',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '113',
    type: 'special113',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '114',
    type: 'special114',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '115',
    type: 'special115',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '116',
    type: 'special116',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '117',
    type: 'special117',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '118',
    type: 'special118',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '119',
    type: 'special119',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '120',
    type: 'special120',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '121',
    type: 'special121',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '122',
    type: 'special122',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '123',
    type: 'special123',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '124',
    type: 'special124',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '125',
    type: 'special125',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '126',
    type: 'special126',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '127',
    type: 'special127',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '128',
    type: 'special128',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '129',
    type: 'special129',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '130',
    type: 'special130',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '131',
    type: 'special131',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '132',
    type: 'special132',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '133',
    type: 'special133',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '134',
    type: 'special134',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '135',
    type: 'special135',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '136',
    type: 'special136',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '137',
    type: 'special137',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '138',
    type: 'special138',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '139',
    type: 'special139',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '140',
    type: 'special140',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '141',
    type: 'special141',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '142',
    type: 'special142',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '143',
    type: 'special143',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '144',
    type: 'special144',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '145',
    type: 'special145',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '146',
    type: 'special146',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '147',
    type: 'special147',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '148',
    type: 'special148',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '149',
    type: 'special149',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '150',
    type: 'special150',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '151',
    type: 'special151',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '152',
    type: 'special152',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '153',
    type: 'special153',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '154',
    type: 'special154',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '155',
    type: 'special155',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '156',
    type: 'special156',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '157',
    type: 'special157',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '158',
    type: 'special158',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '159',
    type: 'special159',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '160',
    type: 'special160',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '161',
    type: 'special161',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '162',
    type: 'special162',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '163',
    type: 'special163',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '164',
    type: 'special164',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '165',
    type: 'special165',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '166',
    type: 'special166',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '167',
    type: 'special167',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '168',
    type: 'special168',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '169',
    type: 'special169',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '170',
    type: 'special170',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '171',
    type: 'special171',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '172',
    type: 'special172',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '173',
    type: 'special173',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '174',
    type: 'special174',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '175',
    type: 'special175',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '176',
    type: 'special176',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '177',
    type: 'special177',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '178',
    type: 'special178',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '179',
    type: 'special179',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '180',
    type: 'special180',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '181',
    type: 'special181',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '182',
    type: 'special182',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '183',
    type: 'special183',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '184',
    type: 'special184',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '185',
    type: 'special185',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '186',
    type: 'special186',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '187',
    type: 'special187',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '188',
    type: 'special188',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '189',
    type: 'special189',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '190',
    type: 'special190',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '191',
    type: 'special191',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '192',
    type: 'special192',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '193',
    type: 'special193',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '194',
    type: 'special194',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '195',
    type: 'special195',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '196',
    type: 'special196',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '197',
    type: 'special197',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '198',
    type: 'special198',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '199',
    type: 'special199',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '200',
    type: 'special200',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '201',
    type: 'special201',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '202',
    type: 'special202',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '203',
    type: 'special203',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '204',
    type: 'special204',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '205',
    type: 'special205',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '207',
    type: 'special207',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '208',
    type: 'special208',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '209',
    type: 'special209',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '210',
    type: 'special210',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '211',
    type: 'special211',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '212',
    type: 'special212',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '213',
    type: 'special213',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '214',
    type: 'special214',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '215',
    type: 'special215',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '216',
    type: 'special216',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '217',
    type: 'special217',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '218',
    type: 'special218',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '219',
    type: 'special219',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '220',
    type: 'special220',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '221',
    type: 'special221',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '222',
    type: 'special222',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '223',
    type: 'special223',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '224',
    type: 'special224',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '225',
    type: 'special225',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '226',
    type: 'special226',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '227',
    type: 'special227',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '228',
    type: 'special228',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '229',
    type: 'special229',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '230',
    type: 'special230',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '231',
    type: 'special231',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '232',
    type: 'special232',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '233',
    type: 'special233',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '243',
    type: 'special243',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '244',
    type: 'special244',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '245',
    type: 'special245',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '246',
    type: 'special246',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '247',
    type: 'special247',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '248',
    type: 'special248',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '249',
    type: 'special249',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '250',
    type: 'special250',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '251',
    type: 'special251',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '252',
    type: 'special252',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '253',
    type: 'special253',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '254',
    type: 'special254',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '255',
    type: 'special255',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  }
]
